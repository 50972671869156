import {React,useState} from 'react'

export function InsertTemplate(props) {

    const [drop, setDrop] = useState('');
    const [fieldName, setFieldName] = useState('');
    const [description, setDescription] = useState('');
    const [testStep, setTestStep] = useState('');
    const [expectedResult, setExpectedResult] = useState('');
    const [onMouseOver, setOnMouseOver] = useState(false);
    const [onMouseOverAction, setOnMouseOverAction] = useState('');
    const [status, setStatus] = useState('');

    const checkHandler = () => {
        setOnMouseOver(!onMouseOver)
    }

    function onSubmit(e) {
        e.preventDefault();

        if(fieldName == '' || drop == ''){
            alert("Type and Field Name are required!")
            return
        }
        
        props.setItem([fieldName,drop,testStep,expectedResult,onMouseOver,onMouseOverAction,description,status]);
        setDrop('');
        setFieldName('');
        setDescription('');
        setTestStep('');
        setExpectedResult('');
        setOnMouseOver(false);
        setOnMouseOverAction('');

    }

    const onChange = (e) => {

        e.preventDefault();

        if(e.target.name === "drop"){
            setDrop(e.target.value)
        }
        if(e.target.name === "FieldName"){
            setFieldName(e.target.value)
        }
        if(e.target.name === "description"){
            setDescription(e.target.value)
        }
        if(e.target.name === "TestStep"){
            setTestStep(e.target.value)
        }
        if(e.target.name === "ExpectedResult"){
            setExpectedResult(e.target.value)
        }
        if(e.target.name === "onMouseOver"){
            setOnMouseOver(e.target.value)
        }
        if(e.target.name === "onMouseOverAction"){
            setOnMouseOverAction(e.target.value)
        }

    }

    return (
        <div>
            <form onSubmit={onSubmit} className="form">
                <select name="drop" value={drop} onChange={onChange}>
                    <option value="Select">Select</option>
                    <option value="Accordion">Accordion</option>
                    <option value="BreadCrumb">BreadCrumb</option>
                    <option value="Button">Button</option>
                    <option value="Carousel">Carousel</option>
                    <option value="Checkbox">Checkbox</option>
                    <option value="DropDown">DropDown</option>
                    <option value="Image">Image</option>
                    <option value="Textbox">Input Textbox</option>
                    <option value="Label">Label</option>
                    <option value="Progress">Progress Bar</option>
                    <option value="Radio">Radio Button</option>
                    <option value="Social">Social Media Sharing</option>
                    <option value="Tab">Tab</option>
                    <option value="Text">Text</option>
                    <option value="Other">Other</option>
                </select>

                <input type="text" name="FieldName" placeholder="*Field Name" value={fieldName} onChange={onChange}/>
                <input type="text" name="description" placeholder="Description" value={description} onChange={onChange}/>
                <input type="text" name="TestStep" placeholder="Test Step" value={testStep} onChange={onChange}/>
                <input type="text" name="ExpectedResult" placeholder="Expected Result" value={expectedResult} onChange={onChange}/>

                <label>
                    <input
                    type="checkbox"
                    name="onMouseOver"
                    checked={onMouseOver}
                    onChange={checkHandler}
                    /> On Mouse Over
                </label>
                <input type="text" name="onMouseOverAction" placeholder="Mouse Over Action" value={onMouseOverAction} onChange={onChange}/>
                <input type="submit" value="Insert new item" className="btn btn-dark btn-block" />
            </form>
        </div>
    )
}


