import React, { Fragment,Component } from 'react';
import { useSortable } from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import Card from 'react-bootstrap/Card';

export function SortableItem(props) {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable ({id: props.id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    return (
        <React.Fragment key={props.id}>
       
        <div ref={setNodeRef} style={style} {...attributes} {...listeners} key={props.id}>
            <Card body className="m-3" style={{"backgroundColor": "WhiteSmoke"}} >

                    {/*<br/>
                     {props.id}
                    <br/>
                    {props.fieldName}
                    <br/>
                    {props.drop}
                    <br/>
                    {props.description}
                    <br/> */}
                    <b>Test Step:</b>
                    <br/>
                    {props.testStep}
                    <br/>
                    <b>Expected Result:</b>
                    <br/>
                    {props.expectedResult}
                    <br/>
                    {/* {String(props.onMouseOver)}
                    <br/>
                    {props.onMouseOverAction} */}

            </Card>
        </div>
        <button onClick={() => {props.removeFunc(props.id)}}>Remove Item</button>
        <button onClick={() => {props.updateFunc(props.id,props.id,props.fieldName,props.drop,props.description,props.testStep,props.expectedResult,props.onMouseOver,props.onMouseOverAction,props.status)}}>Update Item</button>
        </React.Fragment>
    )



}
