import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export function Footer() {

    return (
        <nav className='footer footer-color'>

            <div className='grid-4'>
                <div className='footer-card'>
                <div className='HeaderBar'>Vera</div>
                <div><Link to="/about" className="footer-text">About Us</Link></div>
                <div><a href="mailto:mytestingtools.com@gmail.com" className="footer-text">Contact Us</a></div>
                <div><a href="https://www.paypal.com/donate/?hosted_button_id=FFERGTCZNAFLN" className="footer-text">Donate</a></div>
                </div>
                <div className='footer-card'>
                <div className='HeaderBar'>Legal</div>
                <div><a href="http://www.mytestingtools.com/privacy" className="footer-text">Privacy Policy</a></div>
                <div><a href="http://www.mytestingtools.com/terms" className="footer-text">Terms of Use</a></div>
                </div>
                <div className='footer-card'>
                <div className='HeaderBar'>Useful Links</div>
                <div><a href="mailto:mytestingtools.com@gmail.com?subject=Reporting an issue" className="footer-text">Report A Bug</a></div>
                <div><Link to="/documentation" className="footer-text">Documentation</Link></div>
                <div><a href="http://www.mytestingtools.com/contribute" className="footer-text">How to Contribute</a></div>
                </div>
                <div className='footer-card'>
                <div className='HeaderBar'>Other</div>
                <div><a href="http://www.mytestingtools.com/tools" className="footer-text">Tools</a></div>
                {/*<div><Link to="/projects" className="footer-text">Projects</Link></div>*/}
                <div><a href="http://www.mytestingtools.com/build" className="footer-text">Build with Us</a></div>
                </div>
            </div>  
            <div className="copyright">Copyright © 2015-{new Date().getFullYear()} Abraham Vera. All Rights Reserved</div>
        </nav>
    )
}

