import React from 'react'
import {useState} from 'react';

export function UpdateMobileTemplate(props) {

    const [id, setId] = useState(props.id);
    const [key, setKey] = useState(props.id);
    const [drop, setDrop] = useState(props.drop);
    const [fieldName, setFieldName] = useState(props.fieldName);
    const [description, setDescription] = useState(props.description);
    const [testStep, setTestStep] = useState(props.testStep);
    const [expectedResult, setExpectedResult] = useState(props.expectedResult);
    const [status, setStatus] = useState(props.status);

    function onSubmit(e) {
        e.preventDefault();
        props.updateItemInside(id,key,fieldName,drop,description,testStep,expectedResult,status);
    }

    const onChange = (e) => {

        e.preventDefault();

        if(e.target.name === "drop"){
            setDrop(e.target.value)
        }
        if(e.target.name === "FieldName"){
            setFieldName(e.target.value)
        }
        if(e.target.name === "description"){
            setDescription(e.target.value)
        }
        if(e.target.name === "TestStep"){
            setTestStep(e.target.value)
        }
        if(e.target.name === "ExpectedResult"){
            setExpectedResult(e.target.value)
        }
        if(e.target.name === "status"){
            setStatus(e.target.value)
        }

    }

    return (
        <div>
            <form onSubmit={onSubmit} className="form">
                <select name="drop" value={drop} onChange={onChange}>
                    <option value="Select">Select</option>
                    <option value="Accordion">Accordion</option>
                    <option value="BreadCrumb">BreadCrumb</option>
                    <option value="Button">Button</option>
                    <option value="Carousel">Carousel</option>
                    <option value="Checkbox">Checkbox</option>
                    <option value="DropDown">DropDown</option>
                    <option value="Image">Image</option>
                    <option value="Textbox">Input Textbox</option>
                    <option value="Label">Label</option>
                    <option value="Progress">Progress Bar</option>
                    <option value="Radio">Radio Button</option>
                    <option value="Social">Social Media Sharing</option>
                    <option value="Tab">Tab</option>
                    <option value="Text">Text</option>
                    <option value="Other">Other</option>
                </select>

                <input type="text" name="FieldName" placeholder="*Field Name" value={fieldName} onChange={onChange}/>
                <input type="text" name="description" placeholder="Description" value={description} onChange={onChange}/>
                <input type="text" name="TestStep" placeholder="Test Step" value={testStep} onChange={onChange}/>
                <input type="text" name="ExpectedResult" placeholder="Expected Result" value={expectedResult} onChange={onChange}/>
                <input type="submit" value="Update item" className="btn btn-dark btn-block" />
            </form>
        </div>
    )
}


