import React, { Fragment,Component } from 'react'
import { Link } from 'react-router-dom';
import sgen_screen from './images/sgen_snap01.png';

export function About() {

    return (
        <>
        <div>
            <img src={sgen_screen} alt="ScriptGenerator"/>
        </div>
        <div className="body-container">
            <div className="CenteredTitle">Script Generator (SGEN [es-jen])</div><br/>
            <div className="text-center">Script Generator allows users to easily create test scripts and view them in a table view for easy sharing.<br/> With our easy to use interface, SGEN helps you create a test suite with minimum effort.<br/>
                <b>Table view, Print view, Save your work, Email your work and Save to CSV all with the click of a button!</b><br/>
                Try it today and see how easy it can be to create your next test suite.<br/>
                <Link to="/">Let's write some test cases!</Link> 
                <br/><br/><Link to="/documentation">Teach me how to use the tool!</Link>
            </div>
        </div>
        </>
    )
}

