import React, { Component } from 'react';
import {useState} from 'react';
import { CSVLink } from "react-csv";
import importexport from './images/importexport.png';

export function Import() {

    const [textAreaValue, setTextAreaValue] = useState('');
    const [csv_holder, setCsv_holder] = useState(csv_build());
    const headers = [
        { label: "Description", key: "Description" },
        { label: "Test Step", key: "TestStep" },
        { label: "Expected Result", key: "ExpectedResult" },
        { label: "Status", key: "" }
    ];
    const [ storedData, setStoredData ] = useState(localStorage.getItem('AbesCache'));

    function csv_build() {

        const data_holder = JSON.parse(localStorage.getItem('AbesCache'));

        var csvArray = [];
        data_holder.flatMap(item => {

            csvArray = csvArray.concat({
                'index' : Date().toLocaleString(), 
                'Description' : item.Description,
                'TestStep' : item.TestStep, 
                'ExpectedResult' : item.ExpectedResult,
            })

        });
        
        return csvArray;
    }

    function Email() {
        
    }

    function export_csv() {
        const myBuild = csv_build();
        setCsv_holder(myBuild);
        var link = document.getElementById('csvlink');
        link.click();
    }

    Import = () => {

        var input = textAreaValue;
        const regex = /\[{("\w*":.+)*}\]/;

        if(regex.test(input)){
            localStorage.setItem("AbesCache", textAreaValue);
        } else {
            setTextAreaValue("");
        }

    }

    return (
        <>
            <div className='img-container'>
                <img src={importexport} alt="importexport"/>
            </div>
            <div className="body-container">
                <div className="rightSide">Desktop</div>
                <div className="CenteredTitle">✈ Import ✈</div>

                <div className="text-center">
                Importing your work is as easy as copy / paste!<br/>Simply copy the body of the email you exported to yourself and paste it into the text area below.<br/>
                Then simply click on the Import from Email button.<br/><br/>

                <textarea name="textAreaValue"  value={textAreaValue} onChange={(e) => setTextAreaValue(e.target.value)}/>
                <br/>
                <button onClick={()=>Import()}>Import from Email</button>
                </div>

                <br/>

                <div className="CenteredTitle">✉ Export ✉</div>

                <div className="text-center">
                Saving your work is as easy as clicking a button.<br/> Simply email yourself a copy of your current project for later use or to share with others.<br/>
                Saving your work also allows you to work from other devices without missing a beat!<br/><br/>
                <a href={`mailto:?body=${localStorage.getItem('AbesCache') || "No data to send!"}`}>
                <button onClick={()=>Email()}>Export to Email</button></a>
                </div>
                <div className="text-center">Email not working? Try <a href="#" onClick={() => {navigator.clipboard.writeText(storedData)}}>Copy to Clipboard</a> and paste in an email</div>

                <br/>

                <div className="CenteredTitle">📈 Export CSV 📉</div>

                <div className="text-center">
                You can now Export your work to CSV format!<br/> Simply export yourself a copy of your current project to use for testing reference.<br/>
                Exporting allows you to test with confidence knowing you've covered everything.<br/><br/>
                <button onClick={()=>export_csv()}>Export to CSV</button>
                <CSVLink
                    data={csv_holder}
                    id='csvlink'
                    filename='TestCases.csv'
                    headers={headers}
                    className='hidden'
                    target='_blank'
                />
                </div>

                <br/>

            </div>
        </>
    )
}

