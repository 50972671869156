import React from 'react'

class ComponentToPrint extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            arrayItem: []
          }

        const name = localStorage.getItem('AbesCache');
        if(name){
            this.state = {
                arrayItem: JSON.parse(localStorage.getItem("AbesCache"))
            };
        } else{
            this.state = {
                arrayItem: []
            };
        }

        this.currentpage = localStorage.getItem('PageName');        
        if(typeof(this.currentpage) === 'undefined' || this.currentpage === null){
            this.currentpage = "Table View";
        }
    }

    render() {

        return (
            <div className="container">
                <table style={{textAlign: 'center',borderCollapse: 'collapse', border: '3px solid #ddd', width: '100%'}}>
                    <tbody>
                        <tr>
                            <td colSpan="3"><b>{this.currentpage}</b></td>
                        </tr>
                        <tr>
                        <th style={{textAlign: 'center', border: '1px solid #ddd', padding: '8px', width: '25%'}}>Description</th>
                        <th style={{textAlign: 'center', border: '1px solid #ddd', padding: '8px', width: '25%'}}>Test Step</th>
                        <th style={{textAlign: 'center', border: '1px solid #ddd', padding: '8px', width: '25%'}}>Expected Result</th>
                        <th style={{textAlign: 'center', border: '1px solid #ddd', padding: '8px', width: '25%'}}>Status</th>
                        </tr>

                        {this.state.arrayItem.map(item =>
                            <tr key={item.id}>
                                <td style={{border: '1px solid #ddd', padding: '8px', width: '25%'}}>{item.Description}</td>
                                <td style={{border: '1px solid #ddd', padding: '8px', width: '25%'}}>{item.TestStep}</td>
                                <td style={{border: '1px solid #ddd', padding: '8px', width: '25%'}}>{item.ExpectedResult}</td>
                                <td style={{border: '1px solid #ddd', padding: '8px', width: '25%'}}></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default ComponentToPrint
