import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import ComponentToPrintMobile from "./ComponentToPrintMobile";

export default function PrintComponentMobile() {
    let componentRef = useRef();

    return (
        <React.Fragment>

            {/* button to trigger printing of target component */}
            <ReactToPrint
                trigger={() => <button >Print</button>}
                content={() => componentRef}
            />
    
            {/* component to be printed */}
            <div style={{ display: "none" }}>
                <ComponentToPrintMobile ref={(el) => (componentRef = el)} />
            </div>
        </React.Fragment>
    );
  }