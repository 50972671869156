import React, { Fragment,Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import ScrollToTop from "./components/scrollToTop";
import Nav from './components/nav';
import { Body } from './components/Body';
import { About } from './components/About';
import { Footer } from './components/Footer';
import { Import } from './components/Import';
import { Documentation } from './components/Documentation';
import { Mobile } from './components/Mobile';
import { ImportMobile } from './components/ImportMobile';
import { Live } from './components/Live';
import { AI } from './components/AI';
import { AImobile } from './components/AImobile';
import { LayoutDesign } from './components/LayoutDesign';


class App extends Component { 

  // state = {
  //   table_holder: []
  // }

  // setTableView = tableView => {
  //   this.setState({ table_holder: tableView });
  // }

  render() {

    return (
      <>
      <ScrollToTop />
      <div className="container">
        <Nav title='Script Generator' icon='fas fa-cogs'/>
        <Routes>
          <Route path="/" element={<Body/>} />
          <Route path='/about' element={<About/>} />
          <Route exact path='/footer' element={<Footer/>} />
          <Route exact path='/import' element={<Import/>} />
          <Route exact path='/documentation' element={<Documentation/>} />
          <Route exact path='/mobile' element={<Mobile/>} />
          <Route exact path='/importmobile' element={<ImportMobile/>} />
          <Route exact path='/live' element={<Live/>} />
          <Route exact path='/AI' element={<AI/>} />
          <Route exact path='/AImobile' element={<AImobile/>} />
          <Route exact path='/layoutdesign' element={<LayoutDesign/>} />
        </Routes>
        <Footer />
      </div>
      </>
    );
  }
}

export default App;
