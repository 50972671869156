import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import { FallingLines } from 'react-loader-spinner';
import { Router, Routes, Route, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import '../App.css';
import openAI_img from './images/OpenAI_Logo.png';

export function AI(){

    const [ value, setValue ] = useState("")
    const [ url, setURL ] = useState("")
    const API_KEY = localStorage.getItem("APIKEY");
    const [ responseMessage, setResponseMessage ] = useState(null);
    const [ arrayItems, setArrayItems ] = useState([]);
    const [showResults, setShowResults ] = useState(false);
    const [descModal, setDescModal] = useState(false);
    const [keyPresent, setKeyPresent] = useState(false);
    const blankSpace = "      ";
    
    useEffect(() => {
      if (API_KEY != null){
        setKeyPresent(true);
      } else {
        setKeyPresent(false);
      }
    });
  
    const saveKey = () => {
      localStorage.setItem("APIKEY", (value));
      setKeyPresent(true)
      window.location.reload();
    }

    const deleteKey = () => {
      localStorage.removeItem("APIKEY");
      setKeyPresent(false)
      window.location.reload();
    } 
  
    const submitURL = async () => {
        setShowResults(true);
      const currentMessage = "Create an ordered list of all of the interactive components in the following web page. Specifically call out each individual component. From that list create test cases in the following format 'Test Case; Expected Result;'. Be sure to use the labels 'Test Case:' and 'Expected Result:' when creating the test cases. " + url;
  
      const options = {
        method: "POST",
        headers: {
            "Authorization" : `Bearer ${API_KEY}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            model : "gpt-4",
            messages: [{ role: "user", content: currentMessage}]
            
        })
      }

      try{
          const response = await fetch('https://api.openai.com/v1/chat/completions', options)
          const data = await response.json()
          setResponseMessage(data.choices[0].message.content)
      } catch (err) {
          console.error(err)
      }
      setShowResults(false);
    } 
  
    const handleChange = (e) => {
      setURL(e.target.value);
    };
  
    const handleValueChange = (e) => {
      setValue(e.target.value);
    }

    const navigate = useNavigate();

    const processResponse = () => {

      setArrayItems([]);
      var response_subStr = "";
  
      var start_TC = responseMessage.indexOf("1. Test Case:");
      var end_TC = "";
  
      var start_ER = "";
      var end_ER = "";
  
      var TC_Val = "";
      var ER_Val = "";
   
      response_subStr = responseMessage.substring(start_TC);
      response_subStr = response_subStr + "\n";
  
      var i = 0;
      var cacheVal = ""
  
      while(response_subStr.indexOf("Test Case:")>1) {
  
        start_TC = response_subStr.indexOf("Test Case:", 0);
        end_TC = response_subStr.indexOf("Expected Result:",start_TC);
        TC_Val = response_subStr.substring(start_TC+10,end_TC);
  
        start_ER = response_subStr.indexOf("Expected Result:", 0);
  
        end_ER = response_subStr.indexOf("\n", start_ER);
        ER_Val = response_subStr.substring(start_ER+17,end_ER);
  
        arrayItems.push({
            id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
            index : Date().toLocaleString(), 
            FieldName : "Auto Generated Field Name",
            drop : "", 
            TestStep : TC_Val, 
            ExpectedResult : ER_Val, 
            onMouseOver : "", 
            onMouseOverAction : "", 
            Description : "",
            Status : ""});
  
  
        response_subStr = response_subStr.substring(start_ER+17);
      }
  
      localStorage.setItem("AbesCache", JSON.stringify(arrayItems));
      navigate('/');

    } 
  
    return (

      <div className="App">
        <Modal isOpen={descModal} appElement={document.getElementById('root') || undefined} style={{maxHeight: '100vh', overflowY: 'auto'}} >
          <div className="all-center">
            <div className="openai_img"><img src={openAI_img} /></div>
          </div>
          <br/>
          <br/>
          This application uses <b>GPT 4.0</b>
          <br/>
          In order to use this feature you must provide an openAI API Key. This key will allow you to interact with the openAI service.
          <br/>
          To sign up to openAI and obtain an API key simply visit the <a href="https://openai.com/" target="_blank">OpenAI website</a> and follow the prompts to sign up and create an account.
          <br/> Once you create an account you can obtain your API key here: <a href="https://platform.openai.com/api-keys" target="_blank">Obtain an OpenAI Key</a>
          <br/>
          <br/>
          Our website collects no data from you. All of the data you input is stored in your local browser cache and is removed when you clear your cache.<br/>
          Our website uses a secure connection (https) to connect you to the openAI services.
          <br/>
          We recommend you use this application from a secure machine (personal/non-public) as your openAI key is considered sensitive data.
          <br/>
          <br/>
          <button onClick={() => {setDescModal(false);}} >Close</button>
        </Modal>
        <div className="page-text-right"><b><Link to="/AI" style={{ textDecoration: 'none' }}><span className="page-text-right">Desktop</span></Link></b> / <Link to="/AImobile" style={{ textDecoration: 'none' }}><span className="page-text-right">Mobile</span></Link></div>
        <div><h1>⚙</h1></div>
          <div className="input-container">
            <div>{keyPresent ? <span style={{color: 'green'}}> Key Present</span>  : <span style={{color: 'red'}}> Key Not Present</span> }</div>
            <div className="page-text-right"><a href="#" onClick={() => {setDescModal(true)}} style={{color: 'red'}}>What is this?</a></div>
            <input type="text" onChange={handleValueChange} value={value} placeholder="openAI API Key"/>
            <button onClick={saveKey}>+ Save Key +</button>
            <button onClick={deleteKey}>- Delete Key -</button>
          </div>
          <div className="input-container">
            <input  type="text" onChange={handleChange} value={url} placeholder="URL"/>
            <button onClick={() => submitURL()}>+ Submit URL +</button>
            <button onClick={() => processResponse()}>+ Use Results +</button>
          </div>
          { showResults ?
          <div >

            <FallingLines
            color="#dc3545"
            width="100"
            visible={true}
            ariaLabel="falling-circles-loading"
            />
          </div>
          : null }
          <section className="main">
            <pre>{responseMessage}</pre>
          </section>
  
  
      </div>
    );
}
