import React, { Component } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import {
  DndContext,
  closestCenter
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable"
import {useState} from 'react';
import { Sortable, SortableItem } from '../SortableItem';
import { InsertMobile } from './InsertMobile';
import { UpdateMobileTemplate } from './UpdateMobileTemplate';
import Nav from './nav';
import PrintComponentMobile from './PrintComponentMobile';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

export function Mobile() {

    const [arrayItems, setArrayItems] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("AbesCacheMobile");
        const initialValue = JSON.parse(saved);
        return initialValue || [];
      });

    const [updateModal, setUpdateModal] = useState(false);
    const [tableView, setTableView] = useState(false);

    const [id, setId] = useState('');
    const [key, setKey] = useState('');
    const [drop, setDrop] = useState('');
    const [fieldName, setFieldName] = useState('');
    const [description, setDescription] = useState('');
    const [testStep, setTestStep] = useState('');
    const [expectedResult, setExpectedResult] = useState('');
    const [status, setStatus] = useState('');


    return (

        <div className="container">

        <Modal isOpen={updateModal} appElement={document.getElementById('root') || undefined} style={{maxHeight: '100vh', overflowY: 'auto'}} >
            
            <UpdateMobileTemplate id={id} key={id} fieldName={fieldName} drop={drop} description={description} testStep={testStep} expectedResult={expectedResult} status={status} updateItemInside={updateItemInside}/>
            <br/>
            <button onClick={() => {setUpdateModal(false);}} >Close</button>
        </Modal>

        <Modal isOpen={tableView} appElement={document.getElementById('root') || undefined} style={{maxHeight: '100vh', overflowY: 'auto'}} >
            <h2>Table View</h2>
            <br/>
            <br/>
            <table style={{textAlign: 'center',borderCollapse: 'collapse', border: '3px solid #ddd', width: '100%'}}>
                <tbody>
                    <tr>
                    <th style={{textAlign: 'center', border: '1px solid #ddd', padding: '8px', width: '25%'}}>Description</th>
                    <th style={{textAlign: 'center', border: '1px solid #ddd', padding: '8px', width: '25%'}}>Test Step</th>
                    <th style={{textAlign: 'center', border: '1px solid #ddd', padding: '8px', width: '25%'}}>Expected Result</th>
                    <th style={{textAlign: 'center', border: '1px solid #ddd', padding: '8px', width: '25%'}}>Status</th>
                    </tr>

                    {arrayItems.map(item =>
                        <tr key={item.id}>
                            <td style={{border: '1px solid #ddd', padding: '8px', width: '25%'}}>{item.Description}</td>
                            <td style={{border: '1px solid #ddd', padding: '8px', width: '25%'}}>{item.TestStep}</td>
                            <td style={{border: '1px solid #ddd', padding: '8px', width: '25%'}}>{item.ExpectedResult}</td>
                            <td style={{border: '1px solid #ddd', padding: '8px', width: '25%'}}></td>
                        </tr>
                    )}
                </tbody>
            </table>
            <br/>
            <button onClick={() => {setTableView(false);}} >Close</button>
            <PrintComponentMobile />
        </Modal>
        
        <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <Container className="p-3" style={{"width": "100%"}} align="center">

            {/* <Nav title='Script Generator' icon='fas fa-cogs'/> */}

            {/* <div className="tableView_btn">
                    <button onClick={() => {setTableView(true);}}>Table View</button>
            </div> */}

                <span className="ContentHolder">
                    <button onClick={() => {
                        localStorage.setItem("AbesCacheMobile", JSON.stringify(arrayItems))
                        setTableView(true);
                    }} >Table View</button>
                    {/* <button onClick={() => {
                        localStorage.setItem("AbesCache", JSON.stringify(this.state.arrayItem))
                    this.setState({isLiveModalOpen: true});
                    }} >Live View</button> */}
                    <span>Mobile</span>
                </span>

            <InsertMobile setItem={insertItem}/>

            <SortableContext
                items={arrayItems}
                strategy={verticalListSortingStrategy}
            >
                {arrayItems.map(myItem => <div key={myItem.id}><SortableItem id={myItem.id} fieldName={myItem.FieldName} drop={myItem.drop} testStep={myItem.TestStep} expectedResult={myItem.ExpectedResult} description={myItem.Description} status={myItem.Status} removeFunc={removeItemInside} updateFunc={updateItemInside} /></div> )}
            </SortableContext>


            </Container>
            
        </DndContext>

        <div style={{ height: 500, width: '80%' }}>
            <button onClick={()=>localStorage.setItem("AbesCacheMobile", JSON.stringify(arrayItems))} >
                Save Data</button>
            <button onClick={()=> {
                localStorage.removeItem("AbesCacheMobile");
                localStorage.removeItem("PageName");
                window.location.reload(true);
                window.scrollTo(0, 0);}} >
                Clear Data</button>
            <Link to="/ImportMobile">
                <button onClick={()=>localStorage.setItem("AbesCacheMobile", JSON.stringify(arrayItems))} >
                Import / Export</button></Link>
        </div>

        </div>
    );

    function handleDragEnd(event) {
        const {active, over} = event;

        if(active.id != over.id) {

        setArrayItems((items) => {
            var activeIndex = items.map(function (item) { return item.id; }).indexOf(active.id);
            var overIndex = items.map(function (item) { return item.id; }).indexOf(over.id);

            return arrayMove(items, activeIndex, overIndex);

        });
        }

    }

    function removeItemInside(e) {
        var array = [...arrayItems];
        var index = arrayItems.findIndex(x => x.id === e);
        if (index !== -1) {
            array.splice(index, 1);
            setArrayItems([...array]);
        }
    }

    function updateItemInside(id,key,fieldName,drop,description,testStep,expectedResult,status) {

        setId(id)
        setKey(key)
        setFieldName(fieldName)
        setDrop(drop)
        setDescription(description)
        setTestStep(testStep)
        setExpectedResult(expectedResult)
        setStatus(status)
        setUpdateModal(true);

        const newItem = {
        'key' : id,
        'index' : id,
        'id' : id, 
        'FieldName' : fieldName, 
        'drop' : drop, 
        'TestStep' : testStep, 
        'ExpectedResult' : expectedResult,
        'Description' : description,
        'Status' : status
        }

        var array = [...arrayItems];
        var index = arrayItems.findIndex(x => x.id === id);
        if (index !== -1) {
            array.splice(index, 1, newItem);
            setArrayItems([...array]);
        }
    }

    function insertItem(e) {

        if(e[1]=='Accordion'){
        
            if(e[2] == '' && e[3] == ''){

                setArrayItems(previousInputs => ([...previousInputs, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : "Confirm the " + e[0] + " accordion is displayed", 
                ExpectedResult : "The " + e[0] + " accordion is displayed", 
                Description : e[4],
                Status : e[5]}]));
        
        
                setArrayItems(previousInputs => ([...previousInputs, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : "Tap on / Expand the " + e[0] + " accordion", 
                ExpectedResult : "The " + e[0] + " accordion should expand", 
                Description : e[4],
                Status : e[5]}]));

            } else {
                setArrayItems([...arrayItems, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : e[2], 
                ExpectedResult : e[3],
                Description : e[4],
                Status : e[5]}]);
            }

        }

        if(e[1]=='BreadCrumb'){

            if(e[2] == '' && e[3] == ''){  
                setArrayItems(previousInputs => ([...previousInputs, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : "Confirm the " + e[0] + " breadcrumb is present and the path looks correct", 
                ExpectedResult : "The " + e[0] + " breadcrumb is present and the path looks correct",
                Description : e[4],
                Status : e[5]}]));

            } else {
                setArrayItems([...arrayItems, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : e[2], 
                ExpectedResult : e[3],
                Description : e[4],
                Status : e[5]}]);
            }
        
        }

        if(e[1]=='Button'){
        
            if(e[2] == '' && e[3] == ''){

                setArrayItems(previousInputs => ([...previousInputs, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : "Confirm the " + e[0] + " button is displayed", 
                ExpectedResult : "The " + e[0] + " button is displayed",
                Description : e[4],
                Status : e[5]}]));
        
        
                setArrayItems(previousInputs => ([...previousInputs, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : "Tap on the " + e[0] + " button", 
                ExpectedResult : e[3],
                Description : e[4],
                Status : e[5]}]));

            } else {
                setArrayItems([...arrayItems, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : e[2], 
                ExpectedResult : e[3],
                Description : e[4],
                Status : e[5]}]);
            }

        }

        if(e[1]=='DropDown'){

            if(e[2] == '' && e[3] == ''){  
                setArrayItems(previousInputs => ([...previousInputs, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : "Select a value from the dropdown: " + e[0], 
                ExpectedResult : "The value entered should be displayed in the field",
                Description : e[4],
                Status : e[5]}]));

            } else {
                setArrayItems([...arrayItems, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : e[2], 
                ExpectedResult : e[3],
                Description : e[4],
                Status : e[5]}]);
            }
        
        }

        if(e[1]=='Textbox'){

            if(e[2] == '' && e[3] == ''){  
                setArrayItems(previousInputs => ([...previousInputs, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : "Enter a temporary value into textbox: " + e[0], 
                ExpectedResult : "The value entered should be displayed in the field",
                Description : e[4],
                Status : e[5]}]));

            } else {
                setArrayItems([...arrayItems, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : e[2], 
                ExpectedResult : e[3], 
                Description : e[4],
                Status : e[5]}]);
            }
        
        }

        if(e[1]=='Radio'){

            if(e[2] == '' && e[3] == ''){  
                setArrayItems(previousInputs => ([...previousInputs, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : "Tap on a value displayed by the radio button: " + e[0], 
                ExpectedResult : "The value selected should be displayed for the field",
                Description : e[4],
                Status : e[5]}]));

            } else {
                setArrayItems([...arrayItems, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : e[2], 
                ExpectedResult : e[3],
                Description : e[4],
                Status : e[5]}]);
            }
        
        }

        if(e[1]=='Carousel'){

            if(e[2] == '' && e[3] == ''){  
                setArrayItems(previousInputs => ([...previousInputs, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : "Tap on the carousel navigation button: " + e[0], 
                ExpectedResult : "The carousel should rotate displaying the next piece of content",
                Description : e[4],
                Status : e[5]}]));

            } else {
                setArrayItems([...arrayItems, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : e[2], 
                ExpectedResult : e[3], 
                Description : e[4],
                Status : e[5]}]);
            }
        
        }

        if(e[1]=='Checkbox'){

            if(e[2] == '' && e[3] == ''){  
                setArrayItems(previousInputs => ([...previousInputs, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : "Tap on the checkbox: " + e[0], 
                ExpectedResult : "The checkbox should should become active / selected", 
                Description : e[4],
                Status : e[5]}]));

            } else {
                setArrayItems([...arrayItems, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : e[2], 
                ExpectedResult : e[3], 
                Description : e[4],
                Status : e[5]}]);
            }
        
        }

        if(e[1]=='Image'){

            if(e[2] == '' && e[3] == ''){  
                setArrayItems(previousInputs => ([...previousInputs, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : "Confirm the image is displayed: " + e[0], 
                ExpectedResult : "The image should be displayed", 
                Description : e[4],
                Status : e[5]}]));

            } else {
                setArrayItems([...arrayItems, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : e[2], 
                ExpectedResult : e[3],
                Description : e[4],
                Status : e[5]}]);
            }
        
        }

        if(e[1]=='Label'){

            if(e[2] == '' && e[3] == ''){  
                setArrayItems(previousInputs => ([...previousInputs, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : "Confirm the label is displayed: " + e[0], 
                ExpectedResult : "The label should be displayed", 
                Description : e[4],
                Status : e[5]}]));

            } else {
                setArrayItems([...arrayItems, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : e[2], 
                ExpectedResult : e[3], 
                Description : e[4],
                Status : e[5]}]);
            }
        
        }

        if(e[1]=='Progress'){

            if(e[2] == '' && e[3] == ''){  
                setArrayItems(previousInputs => ([...previousInputs, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : "Trigger the progress bar: " + e[0], 
                ExpectedResult : "The progress bar should display progress made throughout the process", 
                Description : e[4],
                Status : e[5]}]));

            } else {
                setArrayItems([...arrayItems, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : e[2], 
                ExpectedResult : e[3], 
                Description : e[4],
                Status : e[5]}]);
            }
        
        }

        if(e[1]=='Social'){

            if(e[2] == '' && e[3] == ''){  
                setArrayItems(previousInputs => ([...previousInputs, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : "Tap on the Social Media button: " + e[0], 
                ExpectedResult : "You should be redirected to the corresponding social media site",
                Description : e[4],
                Status : e[5]}]));

            } else {
                setArrayItems([...arrayItems, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : e[2], 
                ExpectedResult : e[3], 
                Description : e[4],
                Status : e[5]}]);
            }
        
        }

        if(e[1]=='Tab'){

            if(e[2] == '' && e[3] == ''){  
                setArrayItems(previousInputs => ([...previousInputs, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : "Tap on the Tab: " + e[0], 
                ExpectedResult : "You should be redirected to the corresponding tab", 
                Description : e[4],
                Status : e[5]}]));

            } else {
                setArrayItems([...arrayItems, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : e[2], 
                ExpectedResult : e[3],
                Description : e[4],
                Status : e[5]}]);
            }
        
        }

        if(e[1]=='Text'){

            if(e[2] == '' && e[3] == ''){  
                setArrayItems(previousInputs => ([...previousInputs, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : "Tap on the text displayed: " + e[0], 
                ExpectedResult : "The text should be displayed", 
                Description : e[4],
                Status : e[5]}]));

            } else {
                setArrayItems([...arrayItems, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : e[2], 
                ExpectedResult : e[3], 
                Description : e[4],
                Status : e[5]}]);
            }
        
        }

        if(e[1]=='Other'){

            setArrayItems([...arrayItems, {
                id : "id" + Date.now().toString(36) + Math.random().toString(16).slice(2),
                index : Date().toLocaleString(), 
                FieldName : e[0],
                drop : e[1], 
                TestStep : e[2], 
                ExpectedResult : e[3], 
                Description : e[4],
                Status : e[5]}]);
            }
        
    }
}

