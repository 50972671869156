import React, { Fragment,Component } from 'react'
import { Link } from 'react-router-dom';
import sgen_screen from './images/sgen_snap01.png';

export function Live() { 

    return (
        <Fragment>
        <div>
            <img src={sgen_screen} alt="ScriptGenerator"/>
        </div>
        <div className="body-container">
            <div className="CenteredTitle">Script Generator (SGEN [es-jen])</div><br/>
            <div className="text-center">LIVE TESTING HERE!<br/>
                <b>LIVE</b>
            </div>
        </div>
        </Fragment>
    )

}
