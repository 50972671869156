import React, { Fragment,Component } from 'react';
import logo from './images/documents_banner.png';
import { Link } from 'react-router-dom';
import doc01 from './images/doc01.png';
import doc02 from './images/doc02.png';
import doc03 from './images/doc03.png';
import doc04 from './images/doc04.png';
import doc05 from './images/doc05.png';
import doc06 from './images/doc06.png';
import doc07 from './images/doc07.png';
import doc08 from './images/doc08.png';
import doc09 from './images/doc09.png';
import doc10 from './images/doc10.png';
import doc11 from './images/doc11.png';
import doc12 from './images/doc12.png';
import doc13 from './images/doc13.png';
import doc14 from './images/doc14.png';

export function Documentation() {

    return (
        <Fragment>
            <div className='img-container'>
                <img src={logo} alt="documentation"/>
            </div>
            <div className="body-container">
                <br/>
                <div className="CenteredTitle">Reference</div>
                <br/>
                <div className="text-center">
                <br/><br/>
                Note sure what to write? Don’t worry, SGEN writes test cases for you with as little as a component name and type. 
                <br/>Follow these easy steps to begin using our tool today!
                <br/><br/>Preparation is key!<br/><br/>

                </div>
                <div className='grid-2'>
                    <div className='card'>
                        <div className='cardContents'>
                        Break apart your test subject into its most basic parts. 
                        <br/>Compile a list of components that you will use for writing your test cases.
                        </div>
                    </div>
                    <div className='card'>
                        <div className='cardContents'>
                        <img src={doc01} alt="doc01"/>
                        </div>
                    </div>
                </div>
                <div className='grid-2'>
                    <div className='card'>
                        <div className='cardContents'>
                        <img src={doc02} alt="doc02"/>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='cardContents'>
                        Ask yourself what types of tests can I run on each individual component? 
                        <br/>What types of interactions does this component make with other parts of the system?
                        </div>
                    </div>
                </div>
                <div className='grid-2'>
                    <div className='card'>
                        <div className='cardContents'>
                        What are the different types of responses I can expect from my tests?
                        </div>
                    </div>
                    <div className='card'>
                        <div className='cardContents'>
                        <img src={doc03} alt="doc03"/>
                        </div>
                    </div>
                </div>
                <div className='grid-2'>
                    <div className='card'>
                        <div className='cardContents'>
                        <img src={doc04} alt="doc04"/>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='cardContents'>
                        Once your list is compiled you are ready to begin writing your test cases!
                        </div>
                    </div>
                </div>
                <div className='grid-2'>
                    <div className='card'>
                        <div className='cardContents'>
                        As an example we will write test cases for a simple login page.
                        </div>
                    </div>
                    <div className='card'>
                        <div className='cardContents'>
                        <img src={doc05} alt="doc05"/>
                        </div>
                    </div>
                </div>
                <div className='grid-2'>
                    <div className='card'>
                        <div className='cardContents'>
                        <img src={doc06} alt="doc06"/>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='cardContents'>
                        Our login page is made up of: two input text boxes, one checkbox and one submit button.
                        </div>
                    </div>
                </div>
                <div className='grid-2'>
                    <div className='card'>
                        <div className='cardContents'>
                        Our input text boxes should allow for text input however there are some things to think about. 
                        <br/>Are there any character limitations on input? Is there a character limit for each field? 
                        <br/>Passwords are usually displayed in a hidden format.
                        </div>
                    </div>
                    <div className='card'>
                        <div className='cardContents'>
                        <img src={doc07} alt="doc07"/>
                        </div>
                    </div>
                </div>
                <div className='grid-2'>
                    <div className='card'>
                        <div className='cardContents'>
                        <img src={doc08} alt="doc08"/>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='cardContents'>
                        Our checkbox becomes active or inactive on click. What is the beginning state of our checkbox? 
                        <br/>As a “Remember me” component, is it supposed to remember only the username or both username and password?
                        </div>
                    </div>
                </div>
                <div className='grid-2'>
                    <div className='card'>
                        <div className='cardContents'>
                        On-click of the submit button, should we be redirected to another page? 
                        <br/>What happens if the login information is incorrect? 
                        <br/>What type of message is supposed to be displayed to the user?
                        </div>
                    </div>
                    <div className='card'>
                        <div className='cardContents'>
                        <img src={doc09} alt="doc09"/>
                        </div>
                    </div>
                </div>
                <div className='grid-2'>
                    <div className='card'>
                        <div className='cardContents'>
                        <img src={doc10} alt="doc10"/>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='cardContents'>
                        Using the drop down, select the component type. In our case we are working with an input text box.<br/>
                        Fill in the component name and test description, test step and expected result. <br/>If the component has a mouse over action then include it in your test.<br/>
                        Repeat this process for all of your remaining components.
                        </div>
                    </div>
                </div>
                <div className='grid-2'>
                    <div className='card'>
                        <div className='cardContents'>
                        Click on the "Table View" button to view your scripts in a printable table view. Creating a test document has never been easier!
                        </div>
                    </div>
                    <div className='card'>
                        <div className='cardContents'>
                        <img src={doc11} alt="doc11"/>
                        </div>
                    </div>
                </div>
                <div className='grid-2'>
                    <div className='card'>
                        <div className='cardContents'>
                        <img src={doc12} alt="doc12"/>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='cardContents'>
                        Make sure to save your work!
                        </div>
                    </div>
                </div>
                <div className='grid-2'>
                    <div className='card'>
                        <div className='cardContents'>
                        Saving your work is as easy as clicking a button.<br/>
                        Simply email yourself a copy of your current project for later use or to share with others.<br/>
                        Saving your work also allows you to work from other devices without missing a beat!
                        </div>
                    </div>
                    <div className='card'>
                        <div className='cardContents'>
                        <img src={doc13} alt="doc13"/>
                        </div>
                    </div>
                </div>
                <div className='grid-2'>
                    <div className='card'>
                        <div className='cardContents'>
                        <img src={doc14} alt="doc14"/>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='cardContents'>
                        You can now Export your work to CSV format!<br/>
                        Simply export yourself a copy of your current project and share with your team!<br/>
                        Exporting allows you to test with confidence knowing you've covered everything.
                        </div>
                    </div>
                </div>
                <div className="text-center"><Link to="/">Let's write some test cases!</Link></div>
                
            </div>
        </Fragment>
    )

}
