import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdClose } from "react-icons/md"
import { FiMenu } from "react-icons/fi"
import { FaCogs } from "react-icons/fa";


export default function nav(props) {

    const [toggleMenu, setToggleMenu] = useState(false)
    const toggleNav = () => {
        setToggleMenu(!toggleMenu)
      }

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {

        const changeWidth = () => {
          setScreenWidth(window.innerWidth);
        }
    
        window.addEventListener('resize', changeWidth)
    
        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    
      }, [])

    return (
        <nav className='navbar nav-color'>
        <Link to="/">
        <h1>
            ⚙ {props.title}
        </h1>
        </Link>
        {(toggleMenu || screenWidth > 840) && (
        <ul className="list">
            <li className="items">
                <Link to="/about">About</Link>
            </li>
            <li className="items">
                <Link to="/documentation">Guide</Link>
            </li>
            <li className="items">
                <Link to="/">Desktop</Link>
            </li>
            <li className="items">
                <Link to="/mobile">Mobile</Link>
            </li>
            <li className="items">
                <Link to="/AI">openAI</Link>
            </li>
            <li className="items">
            <a href="http://www.mytestingtools.com/" className="footer-text">Tools</a>
            </li>
            <li className="items">
            <a href="https://www.paypal.com/donate/?hosted_button_id=FFERGTCZNAFLN" className="footer-text">Donate</a>
            </li>
        </ul>
        )}
        <div className="btn_container">
            <div className="centered_button">
                <span onClick={toggleNav} className="nav_btn">    
                {toggleMenu ? (<MdClose style={{ color: "##ffffff", width: "40px", height: "40px" }} />) : (<FiMenu style={{ color: "#ffffff", width: "40px", height: "40px" }} />)}</span>
            </div>
        </div>
    </nav>
    )
}
